@font-face {
  font-family: RYE;
  src: url('./assets/Fonts/Rye/Rye-Regular.ttf');
}
@font-face {
  font-family: Inter;
  src: url('./assets/Fonts/Inter/static/Inter-Regular.ttf');
}
@font-face {
  font-family: Chakra;
  src: url('./assets/Fonts/Chakra_Petch/ChakraPetch-Regular.ttf');
}
@font-face {
  font-family: ChakraBold;
  src: url('./assets/Fonts/Chakra_Petch/ChakraPetch-Bold.ttf');
}
a {
  text-decoration: none;
}
.ResbarIcon {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  display: none;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (max-width: 1500px) {
  .ResbarIcon {
    margin-right: 30px;
  }
}
@media screen and (max-width: 830px) {
  .ResbarIcon {
    display: flex;
  }
}
/* -----------swiper demos ---------------- */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 60%;
}

.swiper-slide:nth-child(2n) {
  width: 40%;
}

.swiper-slide:nth-child(3n) {
  width: 20%;
}
